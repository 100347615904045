import './content-inner.module.scss';
import React, { Component } from 'react';
import dollarCoin from '../../assets/images/dollar-coin.svg';
import ExpertForm from '../expert-form/expert-form';
/* eslint-disable-next-line */
export interface ContentInnerProps {
  heading?: string;
  subheading?: string;
  content?: string;
  subContent;
}

class ContentInner extends Component<ContentInnerProps> {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const { heading, subheading, content, subContent } = this.props;
    return (
      <section id="content-inner">
        <div className="d-flex justify-content-between">
          <div className="content">
            <h3 className="medium-heading color-light-black mar-bottom-rem">
              {heading}
            </h3>
            <p className="color-light-black mar-bottom-rem mar-top-rem">
              {content}{' '}
            </p>
            <br />
            <h4 className="small-heading color-theme mar-top-rem ">
              {subheading}
            </h4>
            <br />
            {subContent}
          </div>
          <ExpertForm />
        </div>
      </section>
    );
  }
}

export default ContentInner;
