import './index.module.scss';
import Layout from '../../../../layout';
import Header from '../../../../components/header/header';
import Footer from '../../../../components/footer/footer';
import ContactForm from '../../../../components/contact-form/contact-form';
import BannerInner from '../../../../components/banner-inner/banner-inner';
import ContentInner from '../../../../components/content-inner/content-inner';
import banner from '../../../../assets/images/services-banner.jpg';
/* eslint-disable-next-line */
export interface IndexProps {}

export function Index(props: IndexProps) {
  return (
    <Layout>
      <Header />
      <BannerInner
        caption={
          <h2 className="x-large-heading color-white">
            Institutional Cash Management
          </h2>
        }
        bannerImage={banner}
      />
      <ContentInner
        heading={'Institutional Cash Management'}
        bannerImage={banner}
        content={
          <p>
            Business owners can need a wide range of payment and cash management
            services that deliver opportunities to enhance their own products
            and cash flows. DGM and its trusted partners can offer various
            international, regional and domestic solutions to alleviate these
            issues. As no two businesses are exactly the same, our menu of cash
            management services offers a wide range of options to meet clients’
            specific requirements thereby providing them with the time and means
            to concentrate on their actual business. In addition, we offer
            market leading products and services that enable our clients to
            reduce transaction costs, create liquidity events, minimize taxes
            and expand cash flow opportunities.
          </p>
        }
      />
      <ContactForm />
      <Footer />
    </Layout>
  );
}

export default Index;
